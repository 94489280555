import './App.css';
import React, { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import indexedDBService from './services/IndexedDBService';
import GameService from './services/GameService';

function App() {
  const [loading, setLoading] = useState(true);
  const [gameService] = useState(new GameService(indexedDBService));
  const [quote, setQuote] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [score, setScore] = useState(0);
  const [count, setCount] = useState(-1);

  useEffect(() => {
    const loadData = async () => {
      try {
        await gameService.initializeGame();
        await generateQuestion();
        setLoading(false);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, [gameService]);

  const generateQuestion = async () => {
    const [quote, options] = await gameService.getQuoteAndOptions();
    setQuote(quote);
    setOptions(options);
    setSelectedOption(null);
    setIsCorrect(null);
    setShowCorrectAnswer(false);
    setCount(count + 1)
  };

  const handleOptionClick = async (option) => {
    setSelectedOption(option);
    if (option.id === quote.bookId) {
      setIsCorrect(true);
      setScore(score + 1)
      await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for the animation to play
      await generateQuestion();
    } else {
      setIsCorrect(false);
      setShowCorrectAnswer(true);
      await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for the animation to play
      await generateQuestion();
    }
  };

  return (
    <div className="App">
      {loading ? (
        <div className="loading-container">
          <Oval
            height={80}
            width={80}
            color="#47b9f9"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#a7c9dc"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="quiz-container">
          <div className="score">Score: {score}/{count}</div>
          <p className="quote">"{quote.text}"</p>
          <div className="options">
            {options.map((option) => (
              <button
                key={option.id}
                onClick={() => handleOptionClick(option)}
                className={`option-button ${selectedOption && selectedOption.id === option.id ? (isCorrect ? 'correct' : 'incorrect') : ''} ${showCorrectAnswer && option.id === quote.bookId ? 'highlight-correct' : ''}`}
              >
                <span className="title">{option.title}</span>
                <span className="author">{option.author.join(', ')}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
