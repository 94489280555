class GameService {
  constructor(indexedDBService) {
    this.indexedDBService = indexedDBService;
  }

  async initializeGame() {
    await this.indexedDBService.loadData();
  }

  async getRandomBooks(excludeBookId, count = 3) {
    if (!this.indexedDBService.books) {
      await this.indexedDBService.loadData();
    }

    // Filter out the book with the given bookId
    const books = this.indexedDBService.books.filter(book => book.id !== excludeBookId);
    const randomBooks = [];

    while (randomBooks.length < count && books.length > 0) {
      const randomIndex = Math.floor(Math.random() * books.length);
      randomBooks.push(books[randomIndex]);
      books.splice(randomIndex, 1); // Remove the selected book to avoid duplicates
    }

    return randomBooks;
  }

  async getRandomQuote() {
    if (!this.indexedDBService.quotes) {
      await this.indexedDBService.loadData();
    }

    const quotes = this.indexedDBService.quotes;
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  }

  async getQuoteAndOptions() {
    if (!this.indexedDBService.books || !this.indexedDBService.quotes) {
      await this.indexedDBService.loadData();
    }

    const randomQuote = await this.getRandomQuote();
    const bookOfQuote = await this.indexedDBService.getBookById(randomQuote.bookId);
    const randomBooks = await this.getRandomBooks(randomQuote.bookId);

    const combinedBooks = [bookOfQuote, ...randomBooks];
    
    // Shuffle the final array
    for (let i = combinedBooks.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [combinedBooks[i], combinedBooks[j]] = [combinedBooks[j], combinedBooks[i]];
    }

    return [randomQuote, combinedBooks];
  }
}

export default GameService;
